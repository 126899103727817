import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./providers/StoreProvider";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./i18n/config";
import i18n from "./i18n/config";
import { I18nextProvider } from "react-i18next";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "./constants/endpoints";
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<StoreProvider>
		<BrowserRouter>
			<Helmet>
				<title>Fabbric Studio</title>
				<meta name="description" content="Fabrica tu ropa desde cero" />
			</Helmet>
			<I18nextProvider i18n={i18n} defaultNS={"translate"}>
				<GoogleOAuthProvider clientId={googleClientId || ""}>
					<App />
				</GoogleOAuthProvider>
			</I18nextProvider>
		</BrowserRouter>
	</StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
