import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Header";
import PaddingManager from "../../components/PaddingManager";
import { AppDispatch, RootState } from "../../store";
import { getItemThunk } from "../../store/Item";
import { Wrap } from "./styles";

import { resetUserDesign } from "../../store/UserDesign";
import { setCSFRToken } from "../../api/csfr";
import { isNewUserRoute, routes } from "../../constants/routes";
import { resetBilling } from "../../store/Billing";
import { resetFabric } from "../../store/Fabric";

import { resetProduction } from "../../store/Production";
import { resetShipping } from "../../store/Shipping";
import {
	hideActionModal,
	resetActionModal,
	resetTab,
	showActionModal,
} from "../../store/UI";
import { resetPrintType } from "../../store/PrintType";

import { useTranslation } from "react-i18next";

import ActionButton from "../../components/ActionButton";
import { orderPlus, tshirtPlus, userPlus } from "../../constants/icons";

import OrderList from "../../components/OrderList";
import DesignList from "../../components/DesignList";
import SubsectionTitle from "../../components/SubsectionTitle";
import { ButtonEnum } from "../../beans";
import Button from "../../components/Button";
import HelpContainer from "../../components/HelpContainer";

declare global {
	interface Window {
		tf?: {
			reload: () => void;
		};
	}
}

const Dashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const user = useSelector((state: RootState) => state.user.data);
	const [hirePremium, setHirePremium] = useState(false);
	// HOOKS
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "//embed.typeform.com/next/embed.js";
		script.async = true;
		script.id = "typeform-script";
		script.defer = true;
		document.head.appendChild(script);

		dispatch(resetProduction());
		dispatch(resetUserDesign());
		dispatch(resetFabric());
		dispatch(resetTab());
		dispatch(resetPrintType());
		dispatch(resetShipping());
		dispatch(resetBilling());
	}, [dispatch]);

	useEffect(() => {
		if (hirePremium) {
			const script = document.createElement("script");
			script.src = "//embed.typeform.com/next/embed.js";
			script.async = true;
			script.id = "typeform-script";
			script.defer = true;
			document.head.appendChild(script);
		} else {
			const loadedScript = document.getElementById("typeform-script");
			if (loadedScript !== null) {
				document.head.removeChild(loadedScript);
			}
			const widget = document.getElementById("typeform-widget");

			if (widget !== null) {
				document.body.removeChild(widget);
			}
		}
	}, [hirePremium]);

	useEffect(() => {
		setCSFRToken();
		dispatch(getItemThunk());
	}, [dispatch]);

	return (
		<>
			<Header />
			{isNewUserRoute(window.location.pathname) && (
				<div
					data-tf-live="01JFF23XM1KHYY573SZWDZS6C7"
					data-tf-hidden={`email=${user?.email},phone=${user?.phone}`}
				></div>
			)}

			<Wrap>
				<PaddingManager>
					<div
						className="hs-form-frame"
						data-region="eu1"
						data-form-id="a0c64b96-8380-443b-a923-55647b7021a7"
						data-portal-id="26490882"
					></div>
					<div className="container">
						<div className="title-container">
							<div className="heading-md title">
								{t("dashboard.hello")}, {user?.first_name}
							</div>
							<div className="body-sm title">{t("dashboard.description")}</div>
						</div>
						<div className="action-container">
							<ActionButton
								icon={tshirtPlus}
								title={t("actionButton.createDesignTitle")}
								onClick={() => navigate(routes.catalog)}
								description={t("actionButton.createDesignDescription")}
							/>
							<ActionButton
								icon={orderPlus}
								title={t("actionButton.orderProductionTitle")}
								onClick={() => navigate(routes.design)}
								description={t("actionButton.orderProductionDescription")}
							/>
							{/* <ActionButton
								icon={quote}
								title={t("actionButton.getQuoteTitle")}
								external
								description={t("actionButton.getQuoteDescription")}
								onClick={() =>
									dispatch(
										showActionModal({
											title: t("airtable.modalTitle"),
											error: `<iframe class="airtable-embed" border="0" src="https://airtable.com/embed/app62wHra2YvuQZR7/pagoOar1xroPYi9C7/form?&prefill_Email=${user?.email}&prefill_Phone=${user?.phone.replace("+", "00")}" frameborder="0" onmousewheel="" width="100%" height="533"></iframe>`,
											actions: [],
											onClose: () => {
												dispatch(resetActionModal());
												dispatch(hideActionModal());
											},
										})
									)
								}
							/> */}
							<ActionButton
								id="hubspot-widget-button"
								icon={userPlus}
								title={t("actionButton.hirePremiumTitle")}
								external
								description={t("actionButton.hirePremiumDescription")}
								onClick={() => {
									dispatch(
										showActionModal({
											minimal: true,
											error: `<iframe src="https://share-eu1.hsforms.com/2oMZLloOARDupI1Vke3Ahpwfrshu" width="100%" height="600px"  style="border:none;"></iframe>`,
											actions: [],
											onClose: () => {
												dispatch(resetActionModal());
												dispatch(hideActionModal());
											},
										})
									);
								}}
							/>
						</div>
						<div>
							<div
								className="hs-form-frame"
								data-region="eu1"
								data-form-id="a0c64b96-8380-443b-a923-55647b7021a7"
								data-portal-id="26490882"
							></div>
						</div>
						<SubsectionTitle title={t("dashboard.recentDesigns")}>
							<Button
								style={ButtonEnum.link}
								onClick={() => navigate(routes.design)}
							>
								{t("dashboard.viewDesigns")}
							</Button>
						</SubsectionTitle>
						<DesignList limit={5} minimal />
						<SubsectionTitle title={t("dashboard.recentOrders")}>
							<Button
								style={ButtonEnum.link}
								onClick={() => navigate(routes.production)}
							>
								{t("dashboard.viewOrders")}
							</Button>
						</SubsectionTitle>
						<OrderList minimal />
					</div>
					<HelpContainer />
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default Dashboard;
