import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
	.title-container {
		margin: auto;
		width: 100%;
		text-align: left;
		margin-top: 40px;
		.description {
			margin-top: 8px;
			color: var(--gray90);
		}
	}

	.section-title {
		padding: 24px 0;
	}

	.action-container {
		display: flex;
		padding: 24px 0;
		gap: 16px;
	}

	.design-grid {
		padding: 24px 0;
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(5, 1fr);
	}

	.custom-product-link {
		margin: 24px auto;
		margin-top: 40px;
		text-align: center;
		a {
			color: var(--purple100);
		}
	}
	.orders-list {
		color: var(--gray90);
		border: 1px solid var(--gray30);
		border-radius: 4px;
		margin: 24px 0;
	}
	.empty-page {
		padding: 40px 0;
		text-align: center;
		color: var(--gray90);
		svg {
			path {
				fill: var(--gray90);
			}
		}
	}
	.design-list {
		.empty-page {
			border: 1px solid var(--gray30);
		}
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		.action-container {
			flex-wrap: nowrap;
		}

		.design-list {
			border: none;
			margin-bottom: 16px;
		}
		.design-grid {
			display: flex;
			grid-template-columns: repeat(2, 1fr);
			border: none;
			overflow: scroll;
			.design-preview-card {
				min-width: 30%;
			}
		}
	}

	@media only screen and (max-width: ${breakpoints.tablet}px) {
		.action-container {
			flex-wrap: wrap;

			button {
				width: 100%;
				padding: 8px;
				.button-content {
					flex-direction: row;
					justify-content: start;
					width: 100%;
					svg {
						padding: 4px;
						border-radius: 100%;
					}
				}
			}
		}
		.design-grid {
			.design-preview-card {
				min-width: 70%;
			}
		}
	}
`;
